import { afterNextRender, Component, OnInit, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ScrollTopModule } from 'primeng/scrolltop';
import { ToastModule } from 'primeng/toast';
import { LoginPageComponent } from './features/login/pages/login-page/login-page.component';
import { PushNotificationConfirmationComponent } from './shared/components/push-notification-confirmation/push-notification-confirmation.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  providers: [ConfirmationService],
  imports: [
    RouterOutlet,
    ScrollTopModule,
    ToastModule,
    ConfirmDialogModule,
    LoginPageComponent,
    PushNotificationConfirmationComponent,
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private primengConfig: PrimeNGConfig,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
